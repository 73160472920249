<template>
  <div class="__full">
    <div class="__10">
      <img src="../../assets/img/logopam.png" alt="" />
    </div>
    <div class="d-flex align-center justify-end flex-column __40">
      <img
        class="mb-5"
        src="../../assets/img/pageNotFound.jpg"
        height="200px"
        alt=""
      />
      <h3 class="mb-5">Oops. The page you're looking for doesn't exist.</h3>
      <v-btn color="#7B1FA2" to="/" dark>
        <v-icon class="mr-2">mdi-home-circle-outline</v-icon>
        <h4>Take me home.</h4></v-btn
      >
    </div>
    <div class="d-flex justify-end align-center flex-column __half footer">
      <h2 class="mb-5 z">404</h2>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#f3f4f5"
          fill-opacity="1"
          d="M0,160L30,144C60,128,120,96,180,74.7C240,53,300,43,360,69.3C420,96,480,160,540,192C600,224,660,224,720,213.3C780,203,840,181,900,192C960,203,1020,245,1080,250.7C1140,256,1200,224,1260,192C1320,160,1380,128,1410,112L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "notfoundpage",
  methods: {}
};
</script>

<style scoped>
.__full {
  height: 100vh;
}
.__10 {
  height: 10vh;
}
.__40 {
  height: 40vh;
}
.__half {
  height: 50vh;
}
.footer {
  position: relative;
}
.svg {
  position: absolute;
  bottom: 0;
}
.z {
  z-index: 2;
}
</style>
